import React from 'react'
import Sidebar from '../components/Sidebar'
import Chat from '../components/Chat'
import logo from "../img/logo.jpg"
const Home = () => {
  return (
 
    <div className='home'>
    <div className='header'>
      <img src = {logo} alt ="logo"/>
      <h1>DesignerDekhrekh</h1>

    </div>
      <div className="container">
        <Sidebar/>
        <Chat/>
      </div>
    </div>
  
  )
}

export default Home