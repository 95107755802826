import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import logo from "../img/logo.jpg";

const Login = () => {
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  const authorizedEmails = ["engineerdekhrekh@gmail.com", "pratik.pashine@gmail.com","nikshahare1998@gmail.com"]; // Add the authorized emails here

  const handleSubmit = async (e) => {
    try {
      const userCred = await signInWithPopup(auth, new GoogleAuthProvider());
      const userEmail = userCred.user.email;

      if (authorizedEmails.includes(userEmail)) {
        await setDoc(doc(db, "users", userCred.user.uid), {
          uid: userCred.user.uid,
          displayName: userCred.user.displayName,
          email: userEmail,
          photoURL: userCred.user.photoURL,
        });

        // Check if the "userChats" document already exists
        try {
          const res = await getDoc(doc(db, "userChats", userCred.user.uid));

          if (!res.exists()) {
            // Create a chat in chats collection
            await setDoc(doc(db, "userChats", userCred.user.uid), {});
          }
        } catch (err) {
          console.log("user chat creation error", err);
        }

        navigate("/home");
      } else {
        // User is not authorized
        setErr(true);
      }
    } catch (err) {
      setErr(true);
    }
  };

  return (
    <div className="formContainer">
      <img src={logo} alt="Logo" />
      <div className="formWrapper">
        <span className="logo">Design Dekhrekh</span>
        {/* <span className="title">Login</span> */}
        <button onClick={handleSubmit}>Sign in with Google</button>
        {err && <p>You are not authorized to log in.</p>}
      </div>
    </div>
  );
};

export default Login;
