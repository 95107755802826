import React, { useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  serverTimestamp,
  setDoc
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "../context/AuthContext";

const Search = () => {
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState([]);
  const [err, setErr] = useState(false);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const searchUsers = async () => {
      if (username.trim() === "") {
        setUsers([]);
        return;
      }

      const q = query(
        collection(db, "users"),
        where("displayName", ">=", username),
        where("displayName", "<=", username + "\uf8ff")
      );

      try {
        const querySnapshot = await getDocs(q);
        const userList = [];
        querySnapshot.forEach((doc) => {
          userList.push(doc.data());
        });
        setUsers(userList);
        setErr(false);
      } catch (err) {
        setUsers([]);
        setErr(true);
      }
    };

    searchUsers();
  }, [username]);

  const handleSelect = async (selectedUser) => {
    const combinedId =
      currentUser.uid > selectedUser.uid
        ? currentUser.uid + selectedUser.uid
        : selectedUser.uid + currentUser.uid;

    try {
      await setDoc(doc(db,`userChats/${currentUser.uid}/lastChat`, selectedUser.uid), {
        id: combinedId,
        userInfo: {
          uid: selectedUser.uid,
          displayName: selectedUser.displayName,
          photoURL: selectedUser.photoURL,
        },
        date: serverTimestamp(),
      });

      await setDoc(doc(db,`userChats/${selectedUser.uid}/lastChat`, currentUser.uid), {
        id: combinedId,
        userInfo: {
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        },
        date: serverTimestamp(),
      });

      setUsername("");
    } catch (err) {
      console.log("Error while selecting user:", err);
    }
  };

  return (
    <div className="search">
      <div className="searchForm">
        <input
          type="text"
          placeholder="Find a user"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
      </div>
      {err && <span>User not found!</span>}
      {users.map((user) => (
        <div className="userChat" key={user.uid} onClick={() => handleSelect(user)}>
          <img src={user.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{user.displayName}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Search;
