import React, { useContext, useState } from "react";
import Img from "../img/img.png";
import Attach from "../img/attach.png";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {
  doc,
  serverTimestamp,
  setDoc,
  collection,
  writeBatch,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const handleSend = async () => {
    try {
      const docRef = doc(collection(db, `chats/${data.chatId}/messages`));
      const userChatref= doc(collection(db,`userChats`))

      if (img) {
        setUploading(true);
        const storageRef = ref(storage, `clientDocument/${uuid()}`);
        const uploadTask = uploadBytesResumable(storageRef, img);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            setUploading(false);
            console.error("Error uploading image:", error);
          },
          async () => {
            setUploading(false);
            setUploadProgress(0);

            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const message = {
              _id: uuid(),
              sentBy: currentUser.uid,
              sentTo: data.user.uid,
              createdAt: serverTimestamp(),
              image: downloadURL,
              text,
              "user":{_id: currentUser.uid}
            };

            await setDoc(docRef, message);
            await setDoc(doc(db,`userChats/${currentUser.uid}/lastChat`,data.user.uid),{
              id : data.chatId,
              userInfo: {
                uid: data.user.uid,
                displayName: data.user.displayName,
                photoURL: data.user.photoURL,
              },
              [`date`]: serverTimestamp(),
              [ `lastMessage`] : 
              {text:"image sent"},
            
      
      
            });
            setDoc(doc(db,`userChats/${data.user.uid}/lastChat`,currentUser.uid),{
              id : data.chatId,
              userInfo: {
                uid: currentUser.uid,
                displayName: currentUser.displayName,
                photoURL: currentUser.photoURL,
              },
              [`date`]: serverTimestamp(),
              [ `lastMessage`] : 
              {text:"you received an image"},
            
      
      
            });
          }
        );
      } else {
        const message = {
          _id: uuid(),
          sentBy: currentUser.uid,
          sentTo: data.user.uid,
          createdAt: serverTimestamp(),
          text,
          user:{'_id': currentUser.uid},
          
        };

        await setDoc(docRef, message);
        await setDoc(doc(db,`userChats/${currentUser.uid}/lastChat`,data.user.uid),{
          id : data.chatId,
          userInfo: {
            uid: data.user.uid,
            displayName: data.user.displayName,
            photoURL: data.user.photoURL,
          },
          [`date`]: serverTimestamp(),
          [ `lastMessage`] : 
          {text},
        
  
  
        });
  
        
  
        setDoc(doc(db,`userChats/${data.user.uid}/lastChat`,currentUser.uid),{
          id : data.chatId,
          userInfo: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [`date`]: serverTimestamp(),
          [ `lastMessage`] : 
          {text},
        
  
  
        });
      }

      

     

      setText("");
      setImg(null);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
          accept="image/png, image/jpeg"
        />
        <label htmlFor="file">
          <img src={Img} alt="" />
        </label>
        <button onClick={handleSend}>Send</button>
      </div>
      {uploading && <div>{uploadProgress.toFixed(1)}% uploaded</div>}
    </div>
  );
};

export default Input;
