import { doc, onSnapshot,query,collection,orderBy } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";

const Chats = () => {
  const [chats, setChats] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

 
  useEffect(() => {
   
    const q =  query(collection(db, `userChats/${currentUser.uid}/lastChat`),orderBy("date", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageData = snapshot.docs.map((doc) => doc.data());
      // Set the state with the new array of document data
      setChats(messageData);
      // console.log("this is message data",messageData[0])
    });

    // Return the unsubscribe function to stop listening when the component unmounts
    return () => unsubscribe();
  }, []);
 

  const handleSelect = (u) => {
    console.log("this is userchats info",u)
    dispatch({ type: "CHANGE_USER", payload: u });
  };
  
  
  return (
    <div className="chats">
       { chats.map((chat)=>(  
         <div
         className="userChat"
         key={chat.userInfo.uid}
         onClick={() => handleSelect(chat.userInfo)}
         >
          <img src={chat.userInfo.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{chat.userInfo.displayName}</span>
            <p>{chat.lastMessage?.text }</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Chats;
